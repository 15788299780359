@import '../../assets/scss/partials/vars';

.blockwrap.video {
    display: block;
    padding: 80px 0px 60px;
    background: rgba(241, 241, 241, 1);

    @media(min-width: $screen-md){
        padding: 100px 0px 60px;
    }

    .video-wrap {
        width: 100%;
        max-width: 800px;
        height: 65vw;
        margin: 0 auto;

        @media(min-width: $screen-md){
            height: 450px;
        }
    }
}   