@import '../../../assets/scss/partials/vars';

.rethink-logo {
    display: block;
    height: 60px;
    cursor: pointer;

    .st0 {
        fill: $rethink-blue;
        transition: all 0.35s linear;
    }

    &:hover {
       
        .st0 {
            fill:$footer-grey;
        }
    }
}