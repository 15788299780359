@import '../../assets/scss/partials/vars';

.social-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
   

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        width: 38px;
        margin: 0 6px;
        //background: rgb(130, 14, 14);
        color: rgb(72, 195, 212);
        border-radius: 25px;
        font-size: 2.3rem;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.25s linear;


        @media(min-width: $screen-md){
            margin: 0 2px;
            font-size: 2.5rem;
        }

        &:hover {
            color: #fc7d71;
        }
    }
}
