@import '../../../assets/scss/partials/vars';

.icon-calendar{
    height: 30px;
    //fill: rgb(0, 187, 255);
    
    &:hover {
        fill: #fc7d71;
        transition: all 0.35s linear;
    }

    .st0 {
        fill: none;
        stroke: $rethink-blue;
        stroke-width: 2;
        stroke-miterlimit: 10;
    }
}