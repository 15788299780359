@import '../../../assets/scss/partials/vars';

.btn-social {
    height: 24px;
    fill: rgb(0, 187, 255);
    
    &:hover {
        //fill: #fc7d71;
        fill: $white;
        transition: all 0.35s linear;
    }
}

.footer {
    .social-wrap {
        .btn-social {
            height: 24px;
            fill: $white;
            
            &:hover {
                fill: $rethink-blue;
                transition: all 0.35s linear;
            }
        }
    }
}