/* VARS - WEBSITE =====================================
======================================================= */
$white: #FFFFFF;
$black: #000000;
$rethink-blue: rgba(107, 191, 253, 1);
$rollover-blue: rgba(63, 133, 184, 1);
$footer-grey: rgba(127, 127, 127, 1);
$headlines-dark: rgba(57, 57, 57, 1);
$body-copy: rgba(98, 98, 98, 1);
$bg-grey: rgba(78, 78, 78, 1);
// General

// FONTS ============================================== */
$baseFont: scandia-web, arial, sans-serif;

/* BREAKPOINTS ======================================== */

// smartphones (portrait view)
$screen-xs: 374px;

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 1024px;

// Large tablets and desktops
$screen-xl: 1200px;

// Super large
$screen-max: 1600px;
