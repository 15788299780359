@import '../../assets/scss/partials/vars';

.blockwrap.home {
    &.top {
        background: $bg-grey;
        color: $white;
    }
    h1.title-logo {
        display: block;
        height: 65px;
        margin: 16px 0 40px;
        color: $rethink-blue;
        font-family: $baseFont;
        font-size: 1.6rem;
        font-weight: 500;
        font-style: normal;
        text-align: left;
        letter-spacing: -1px;
    
        @media(min-width: $screen-md){
            height: 100px;
            margin: 35px 0 40px;
            font-size: 2rem;
        }

        // sup {
        //     display: block;
        //     transform: scaleX(-1);
        // }
    }

    h2.subhead {
        font-size: 2.1rem;
        font-weight: 500;
        line-height: 1.1;

        @media(min-width: $screen-sm){
            font-size: 2.8rem;
        }

        @media(min-width: $screen-md){
            font-size: 2.95rem;
        }
    }

    .buttons-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 40px auto 60px;

        @media(min-width: $screen-md){
            flex-direction: row;
            justify-content: space-around;
            width: 74%;
            margin: 55px auto 140px;
        }


        a.calendar-option, div.calendar-option {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 12px auto;
            padding: 14px 8px;
            width: 200px;
            border: 1px solid $white; 
            border-radius: 30px;
            background-color: $bg-grey;
            color: $white;
            font-family: $baseFont;
            font-size: 1.2rem;
            font-weight: 500;
            letter-spacing: -0.5px;
            text-decoration: none;
            transition: all 0.35s linear;
            cursor: pointer;

            svg {
                margin-right: 10px;
            }

            @media(min-width: $screen-md){
                margin: 0 4px;
            }

            &:hover {
                color: $headlines-dark;
                border-color: $headlines-dark;
                background-color: $white;
            }

        }
    }
   
}
.blockwrap.share-block {
    background: rgba(241, 241, 241, 1);

    h3 {
        display: block;
        margin: 55px auto 40px;
        color: $headlines-dark;
        font-family: $baseFont;
        font-size: 1.35rem;
        font-weight: 500;

        @media(min-width: $screen-xs){
            font-size: 1.5rem;
        }

        @media(min-width: $screen-sm){
            font-size: 2rem;
        }

        @media(min-width: $screen-md){
            font-size: 2rem;
        }
    }

    .options-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media(min-width: $screen-md){
            flex-direction: row;
        }

        .option {
            box-sizing: border-box;
            display: block;
            width: 100%;
            max-width: 320px;
            //height: 365px;
            margin: 0px auto 20px;
            padding-bottom: 25px;
            background: $white;
            border: 1px solid $headlines-dark;

            @media(min-width: $screen-md){
                display: inline-block;
                width: 350px;
                margin: 0 30px;
               // height: 360px;
            }

            span {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                width: 100%;
                height: 25px;
                padding-left: 8px;
                background: $rethink-blue;
                border-bottom: 1px solid $headlines-dark;
                text-align: left;

                div.circle {
                    display: inline-block;
                    height: 8px;
                    width: 8px;
                    margin: 0 2px;
                    border: 1px solid $headlines-dark;
                    border-radius: 6px;
                }
            }

            h4 {
                margin: 30px 20px 0;
                color: $headlines-dark;
                font-family: $baseFont;
                font-size: 1.36rem;
                font-weight: 600;
                text-align: left;
            }
            p{
                padding: 25px 20px 30px;
                color: $body-copy;
                font-family: $baseFont;
                font-size: 1rem;
                line-height: 1.4;
                text-align: left;
               
            }
        }
    }

    .btn-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        a, button {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 8px; 
            margin: 0 6px;
            width: 130px;
            background: none;
            border: 1px solid $rethink-blue;
            border-radius: 28px;
            color: $rethink-blue;
            text-align: center;
            text-decoration: none;
            font-family: $baseFont;
            font-size: 1.2rem;
            font-weight: 500;
            cursor: pointer;

            svg {
                margin: 0 8px 0 0;
                font-size: 1.2rem;
            }

            &:hover {
                color: $white;
                background: $rethink-blue;
                transition: all 0.35s linear;

                svg {
                    fill: $white;
                    color: $rethink-blue;
                    transition: all 0.35s linear;
                }
            }
        }
    }
}

.blockwrap.footer {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    height: 300px;
    background: rgba(241, 241, 241, 1);
    color: $white;
    font-family: $baseFont;
    font-size: 0.8rem;

    .social-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 50px;

        @media(min-width: $screen-md){
            margin-top: 90px;
        }

        a {
            margin: 0 8px;
        }

    }

    p {
        margin: 20px auto;
    }
}