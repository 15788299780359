@import '../../../assets/scss/partials/vars';

.independents-week-logo {
    display: block;
    height: 55px;

    @media(min-width: $screen-md){
        height: 65px;
    }

    .st0 { 
        fill:$rethink-blue;
       /* fill:#31C5F4; */
    }
}